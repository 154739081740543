export default {
  "hat": [
    {
      "value": "Black Backwards Cap",
      "amount": 374
    },
    {
      "value": "Military Helmet",
      "amount": 468
    },
    {
      "value": "Sailor hat",
      "amount": 243
    },
    {
      "value": "White Backwards Cap",
      "amount": 352
    },
    {
      "value": "Angel Halo",
      "amount": 99
    },
    {
      "value": "ETH Hat",
      "amount": 232
    },
    {
      "value": "Leaf Crown",
      "amount": 490
    },
    {
      "value": "Devil Horns",
      "amount": 100
    },
    {
      "value": "Blue Bucket Hat",
      "amount": 385
    },
    {
      "value": "Headphones",
      "amount": 451
    },
    {
      "value": "Fireman Hat",
      "amount": 219
    },
    {
      "value": "Black Bucket Hat",
      "amount": 416
    },
    {
      "value": "Black Beanie",
      "amount": 338
    },
    {
      "value": "Cowboy Hat",
      "amount": 377
    },
    {
      "value": "Military Beret",
      "amount": 288
    },
    {
      "value": "Police Hat",
      "amount": 286
    },
    {
      "value": "Red Beanie",
      "amount": 283
    },
    {
      "value": "Rapper Cap",
      "amount": 290
    },
    {
      "value": "Sport Headband",
      "amount": 235
    },
    {
      "value": "Black ICON Hat",
      "amount": 100
    },
    {
      "value": "White ICON Hat",
      "amount": 101
    },
    {
      "value": "Magician Hat",
      "amount": 190
    },
    {
      "value": "Crown",
      "amount": 192
    },
    {
      "value": "Good & Evil",
      "amount": 6
    },
    {
      "value": "Mr. Monopoly Hat",
      "amount": 1
    },
    {
      "value": "LEGO Beanie",
      "amount": 1
    },
    {
      "value": "LEGO Headphones",
      "amount": 1
    },
    {
      "value": "Grey Hat",
      "amount": 1
    },
    {
      "value": "LEGO Devil Horns",
      "amount": 1
    }
  ],
  "material": [
    {
      "value": "Bronze",
      "amount": 90
    },
    {
      "value": "Concrete",
      "amount": 820
    },
    {
      "value": "Light Marble",
      "amount": 1500
    },
    {
      "value": "Black Plastic",
      "amount": 3500
    },
    {
      "value": "White Plastic",
      "amount": 3500
    },
    {
      "value": "Glass",
      "amount": 360
    },
    {
      "value": "Robotic",
      "amount": 200
    },
    {
      "value": "Gold",
      "amount": 23
    },
    {
      "value": "Yellow LEGO",
      "amount": 1
    },
    {
      "value": "Money Signs",
      "amount": 1
    },
    {
      "value": "Blue LEGO",
      "amount": 1
    },
    {
      "value": "Red LEGO",
      "amount": 1
    },
    {
      "value": "White LEGO",
      "amount": 1
    },
    {
      "value": "Human CP",
      "amount": 1
    },
    {
      "value": "Black LEGO",
      "amount": 1
    }
  ],
  "earring": [
    {
      "value": "Diamond earring",
      "amount": 468
    },
    {
      "value": "Gold Hoop",
      "amount": 377
    },
    {
      "value": "Silver Hoop",
      "amount": 567
    },
    {
      "value": "Black Hoop",
      "amount": 691
    }
  ],
  "mouth": [
    {
      "value": "Cigar",
      "amount": 251
    },
    {
      "value": "Money Bandana",
      "amount": 152
    },
    {
      "value": "Grey Surgical mask",
      "amount": 463
    },
    {
      "value": "White Mustache",
      "amount": 451
    },
    {
      "value": "Cigarette",
      "amount": 552
    },
    {
      "value": "Weed Joint",
      "amount": 250
    },
    {
      "value": "Vape",
      "amount": 250
    },
    {
      "value": "Black Surgical mask",
      "amount": 466
    },
    {
      "value": "Smoke Pipe",
      "amount": 100
    },
    {
      "value": "Toothpick",
      "amount": 401
    },
    {
      "value": "Black Mustache",
      "amount": 353
    },
    {
      "value": "Red Bandana",
      "amount": 360
    },
    {
      "value": "LEGO Mask",
      "amount": 1
    },
    {
      "value": "Cigarette + Frown",
      "amount": 1
    }
  ],
  "eyes": [
    {
      "value": "White X",
      "amount": 801
    },
    {
      "value": "3D Glasses",
      "amount": 251
    },
    {
      "value": "Silver Coin",
      "amount": 452
    },
    {
      "value": "Sunglasses",
      "amount": 526
    },
    {
      "value": "VR Glasses",
      "amount": 191
    },
    {
      "value": "Reading Glasses",
      "amount": 500
    },
    {
      "value": "Black X",
      "amount": 802
    },
    {
      "value": "Eye Patch",
      "amount": 301
    },
    {
      "value": "Futuristic Glasses",
      "amount": 451
    },
    {
      "value": "Thieves Mask",
      "amount": 347
    },
    {
      "value": "ICON Coin",
      "amount": 51
    },
    {
      "value": "Cyclops Visor",
      "amount": 352
    },
    {
      "value": "Monocle",
      "amount": 351
    },
    {
      "value": "Old Glasses",
      "amount": 400
    },
    {
      "value": "Eye Black",
      "amount": 401
    },
    {
      "value": "Gold Coin",
      "amount": 251
    },
    {
      "value": "Ski Goggles",
      "amount": 267
    },
    {
      "value": "Monopoly Monocle",
      "amount": 1
    },
    {
      "value": "LEGO Coin",
      "amount": 1
    },
    {
      "value": "LEGO X Eyes",
      "amount": 1
    },
    {
      "value": "Classic Shades",
      "amount": 1
    },
    {
      "value": "LEGO Futuristic Glasses",
      "amount": 1
    }
  ],
  "face": [
    {
      "value": "Black Nose Piercing",
      "amount": 401
    },
    {
      "value": "Ski Mask",
      "amount": 352
    },
    {
      "value": "Mind Stone",
      "amount": 451
    },
    {
      "value": "Gas Mask",
      "amount": 119
    },
    {
      "value": "Silver Nose Piercing",
      "amount": 351
    },
    {
      "value": "Space Helmet",
      "amount": 125
    },
    {
      "value": "Spartan Helmet",
      "amount": 135
    },
    {
      "value": "Gold Nose Piercing",
      "amount": 251
    },
    {
      "value": "LEGO Ski Mask",
      "amount": 1
    }
  ],
  "neck": [
    {
      "value": "Diamond Chain",
      "amount": 923
    },
    {
      "value": "Cuban Chain",
      "amount": 578
    },
    {
      "value": "Death Collar",
      "amount": 451
    },
    {
      "value": "ETH Chain",
      "amount": 643
    },
    {
      "value": "ICON Chain",
      "amount": 201
    },
    {
      "value": "LEGO Gold Chain",
      "amount": 1
    }
  ],
  "body": [
    {
      "value": "White Hoodie",
      "amount": 27
    },
    {
      "value": "Black Hoodie",
      "amount": 28
    }
  ]
}