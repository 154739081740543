import Collectable from '../../build/contracts/Incognito.json';
import wallet from './wallet';

const networks = {
  1: 'Mainnet',
  3: 'Ropsten',
  4: 'Rinkeby',
  5: 'Goerli',
  42: 'Kovan',
}

const CollectableContractABI = {
  async getContractInstance() {
    if (window.CollectableInstance) {
      return window.CollectableInstance;
    } else {
      // Get contract instance
      // Get Network ID where contract is deployed
      // This will get you the contract address
      const networkId = await window.web3.eth.net.getId();      
      const contractNetwork = Collectable.networks[networkId];
      if (!contractNetwork) {
        throw new Error(`Contract not found - Check that you are in the correct network, network detected: ${networks[networkId]}`);
      }
      const CollectableInstance = new window.web3.eth.Contract(
        Collectable.abi,
        process.env.NFT_CONTRACT_ADDRESS || contractNetwork.address,
      );
      window.CollectableInstance = CollectableInstance
      return window.CollectableInstance;
    }
  },
  async mintNfts(numNFTs) {
    const ether = numNFTs * 85000000000000000
    const CollectableInstance = await this.getContractInstance();
    return CollectableInstance.methods.mint(numNFTs).send({
      from: wallet.address,
      value: ether
    });
  },
  async mintNFTPresale() {
    const ether = 85000000000000000
    const CollectableInstance = await this.getContractInstance();
    return CollectableInstance.methods.mintPreSale().send({
      from: wallet.address,
      value: ether
    });
  },
  async getPreSaleState() {  
    const CollectableInstance = await this.getContractInstance();
    return CollectableInstance.methods.hasPreSaleStarted().call();
  },
  async flipPreSaleState() {  
    const CollectableInstance = await this.getContractInstance();
    return CollectableInstance.methods.flipPreSaleState().send({
      from: wallet.address,
    });
  },
  async getSaleState() {  
    const CollectableInstance = await this.getContractInstance();
    return CollectableInstance.methods.hasSaleStarted().call();
  },
  async getTotalSupply() {  
    const CollectableInstance = await this.getContractInstance();
    return CollectableInstance.methods.totalSupply().call();
  },
  async flipSaleState() {  
    const CollectableInstance = await this.getContractInstance();
    return CollectableInstance.methods.flipSaleState().send({
      from: wallet.address,
    });
  },
  async isTheOwner(){
    const CollectableInstance = await this.getContractInstance();
    const ownerAddress = await CollectableInstance.methods.owner().call();
    return ownerAddress === wallet.address
  },
  async getOwner(){
    const CollectableInstance = await this.getContractInstance();
    const ownerAddress = await CollectableInstance.methods.owner().call();
    return ownerAddress;
  },
  async withdraw() {
    const CollectableInstance = await this.getContractInstance();
    return CollectableInstance.methods.withdraw().send({
      from: wallet.address,
    });
  },
  async transferOwnership(newOwner) {
    const CollectableInstance = await this.getContractInstance();
    return CollectableInstance.methods.transferOwnership(newOwner).send({
      from: wallet.address,
    });
  },
  async setBaseURI(newBaseURI) {
    const CollectableInstance = await this.getContractInstance();
    return CollectableInstance.methods.setBaseURI(newBaseURI).send({
      from: wallet.address,
    });
  },
  async getBaseURI() {
    const CollectableInstance = await this.getContractInstance();
    return CollectableInstance.methods.getBaseURI().call();
  },
  async setProvenanceHash(provenanceHash) {
    const CollectableInstance = await this.getContractInstance();
    return CollectableInstance.methods.setProvenanceHash(provenanceHash).send({
      from: wallet.address,
    });
  },
  async getProvenanceHash() {
    const CollectableInstance = await this.getContractInstance();
    return CollectableInstance.methods.INCOGNITO_HASH().call();
  },
  async getUserTokenAmount(address){
    const CollectableInstance = await this.getContractInstance();
    return CollectableInstance.methods.balanceOf(address).call();
  },
  async tokenOfOwnerByIndex(address, index){
    const CollectableInstance = await this.getContractInstance();
    return CollectableInstance.methods.tokenOfOwnerByIndex(address, index).call();    
  },
  async getTokenURIById(id) {
    const CollectableInstance = await this.getContractInstance();
    return CollectableInstance.methods.tokenURI(id).call();
  },
}
export default CollectableContractABI;
