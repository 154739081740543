{
  "_from": "web3@^1.5.1",
  "_id": "web3@1.6.0",
  "_inBundle": false,
  "_integrity": "sha512-rWpXnO88MiVX5yTRqMBCVKASxc7QDkXZZUl1D48sKlbX4dt3BAV+nVMVUKCBKiluZ5Bp8pDrVCUdPx/jIYai5Q==",
  "_location": "/web3",
  "_phantomChildren": {
    "@ethereumjs/common": "2.5.0",
    "@ethereumjs/tx": "3.3.2",
    "@ethersproject/abi": "5.0.7",
    "@ethersproject/transactions": "5.5.0",
    "bignumber.js": "9.0.1",
    "content-hash": "2.5.2",
    "create-hash": "1.2.0",
    "crypto-browserify": "3.12.0",
    "elliptic": "6.5.4",
    "eth-ens-namehash": "2.0.8",
    "ethereum-bloom-filters": "1.0.10",
    "ethereum-cryptography": "0.1.3",
    "ethjs-unit": "0.1.6",
    "got": "9.6.0",
    "number-to-bn": "1.7.0",
    "oboe": "2.1.5",
    "randombytes": "2.1.0",
    "rlp": "2.2.7",
    "scrypt-js": "3.0.1",
    "swarm-js": "0.1.40",
    "utf8": "3.0.0",
    "util": "0.12.4",
    "websocket": "1.0.34",
    "xhr-request-promise": "0.1.3",
    "xhr2-cookies": "1.1.0"
  },
  "_requested": {
    "type": "range",
    "registry": true,
    "raw": "web3@^1.5.1",
    "name": "web3",
    "escapedName": "web3",
    "rawSpec": "^1.5.1",
    "saveSpec": null,
    "fetchSpec": "^1.5.1"
  },
  "_requiredBy": [
    "/"
  ],
  "_resolved": "https://registry.npmjs.org/web3/-/web3-1.6.0.tgz",
  "_shasum": "d8fa0cd9e7bf252f9fe43bb77dc42bc6671affde",
  "_spec": "web3@^1.5.1",
  "_where": "/opt/build/repo",
  "author": {
    "name": "ethereum.org"
  },
  "authors": [
    {
      "name": "Fabian Vogelsteller",
      "email": "fabian@ethereum.org",
      "homepage": "http://frozeman.de"
    },
    {
      "name": "Marek Kotewicz",
      "email": "marek@parity.io",
      "url": "https://github.com/debris"
    },
    {
      "name": "Marian Oancea",
      "url": "https://github.com/cubedro"
    },
    {
      "name": "Gav Wood",
      "email": "g@parity.io",
      "homepage": "http://gavwood.com"
    },
    {
      "name": "Jeffery Wilcke",
      "email": "jeffrey.wilcke@ethereum.org",
      "url": "https://github.com/obscuren"
    }
  ],
  "bugs": {
    "url": "https://github.com/ethereum/web3.js/issues"
  },
  "bundleDependencies": false,
  "dependencies": {
    "web3-bzz": "1.6.0",
    "web3-core": "1.6.0",
    "web3-eth": "1.6.0",
    "web3-eth-personal": "1.6.0",
    "web3-net": "1.6.0",
    "web3-shh": "1.6.0",
    "web3-utils": "1.6.0"
  },
  "deprecated": false,
  "description": "Ethereum JavaScript API",
  "devDependencies": {
    "@types/node": "^12.12.6",
    "dtslint": "^3.4.1",
    "typescript": "^3.9.5",
    "web3-core-helpers": "1.6.0"
  },
  "engines": {
    "node": ">=8.0.0"
  },
  "gitHead": "a34afae56647615d7cbdfa227af8a1389476e2d6",
  "homepage": "https://github.com/ethereum/web3.js#readme",
  "keywords": [
    "Ethereum",
    "JavaScript",
    "API"
  ],
  "license": "LGPL-3.0",
  "main": "lib/index.js",
  "name": "web3",
  "repository": {
    "type": "git",
    "url": "git+https://github.com/ethereum/web3.js.git"
  },
  "scripts": {
    "compile": "tsc -b tsconfig.json",
    "dtslint": "dtslint --localTs ../../node_modules/typescript/lib types",
    "postinstall": "echo \"WARNING: the web3-shh and web3-bzz api will be deprecated in the next version\""
  },
  "types": "types/index.d.ts",
  "version": "1.6.0"
}
