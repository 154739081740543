export default {
  setWallet: ({ commit, dispatch }, { address }) => {
    commit('setWallet', address);
    dispatch('collectable/getUserTokens', {}, { root: true });
  },
  disconectWallet: ({ commit }, { address }) => {
    commit('setWallet', address);
    commit('setWallet', address);
  }
}
