import axios from 'axios';

axios.defaults.baseURL = process.env.API_URI;

export default {
  getNFTDetails: (nftId) => {
    return axios.get(`/api/gallery/?number=${nftId}`)
  },
  galleryGetNFTs: (url) => {
    return axios.get(url)
  },
  getAttributesAPI: (filter) => {
    return axios.get(`/api/gallery/attributes?filter=${filter}`)
  }
}