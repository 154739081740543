{
  "_from": "walletlink@^2.1.9",
  "_id": "walletlink@2.2.6",
  "_inBundle": false,
  "_integrity": "sha512-4TF1kkpo9aq1QlfKv6jTCEsV8Rc+1RIuXn2EtsTJt9/H02fG3oy7k49sqB4gXZ9CWN48yoXnmSwq1GdkvfYGjw==",
  "_location": "/walletlink",
  "_phantomChildren": {
    "@metamask/safe-event-emitter": "2.0.0",
    "base64-js": "1.5.1",
    "fast-safe-stringify": "2.1.1",
    "ieee754": "1.2.1",
    "inherits": "2.0.4",
    "readable-stream": "3.6.0"
  },
  "_requested": {
    "type": "range",
    "registry": true,
    "raw": "walletlink@^2.1.9",
    "name": "walletlink",
    "escapedName": "walletlink",
    "rawSpec": "^2.1.9",
    "saveSpec": null,
    "fetchSpec": "^2.1.9"
  },
  "_requiredBy": [
    "/"
  ],
  "_resolved": "https://registry.npmjs.org/walletlink/-/walletlink-2.2.6.tgz",
  "_shasum": "cfea3ba94e5ea33e87b0a2f31151a77ee1a59d72",
  "_spec": "walletlink@^2.1.9",
  "_where": "/opt/build/repo",
  "author": {
    "name": "Coinbase, Inc."
  },
  "bugs": {
    "url": "https://github.com/walletlink/walletlink/issues"
  },
  "bundleDependencies": false,
  "dependencies": {
    "@metamask/safe-event-emitter": "2.0.0",
    "bind-decorator": "^1.0.11",
    "bn.js": "^5.1.1",
    "buffer": "^6.0.3",
    "clsx": "^1.1.0",
    "eth-block-tracker": "4.4.3",
    "eth-json-rpc-filters": "4.2.2",
    "eth-rpc-errors": "4.0.2",
    "js-sha256": "0.9.0",
    "json-rpc-engine": "6.1.0",
    "keccak": "^3.0.1",
    "preact": "^10.5.9",
    "rxjs": "^6.6.3",
    "stream-browserify": "^3.0.0",
    "util": "^0.12.4"
  },
  "deprecated": false,
  "description": "WalletLink JavaScript SDK",
  "devDependencies": {
    "@types/bn.js": "^4.11.6",
    "@types/node": "^14.14.20",
    "browserify": "17.0.0",
    "copy-webpack-plugin": "^6.4.1",
    "core-js": "^3.8.2",
    "jasmine": "3.8.0",
    "karma": "^6.3.2",
    "karma-browserify": "8.1.0",
    "karma-chrome-launcher": "^3.1.0",
    "karma-jasmine": "^4.0.1",
    "nodemon": "^2.0.6",
    "raw-loader": "^4.0.2",
    "regenerator-runtime": "^0.13.7",
    "rxjs-tslint": "^0.1.7",
    "sass": "^1.32.0",
    "svgo": "^1.3.2",
    "ts-jest": "^26.4.4",
    "ts-loader": "^8.0.13",
    "tslib": "^2.0.3",
    "tslint": "^6.1.3",
    "tslint-config-prettier": "^1.18.0",
    "tslint-config-security": "^1.16.0",
    "tslint-microsoft-contrib": "^6.2.0",
    "typescript": "^4.1.3",
    "watchify": "4.0.0",
    "webpack": "^5.49.0",
    "webpack-cli": "^3.3.12",
    "whatwg-fetch": "^3.5.0"
  },
  "engines": {
    "node": ">= 10.0.0"
  },
  "homepage": "https://github.com/walletlink/walletlink#readme",
  "jest": {
    "transform": {
      "^.+\\.tsx?$": "ts-jest"
    },
    "testEnvironment": "node",
    "testPathIgnorePatterns": [
      "<rootDir>/dist/",
      "<rootDir>/node_modules/"
    ],
    "testRegex": "(/__tests__/.*|\\.(test|spec))\\.(ts|tsx|js)$",
    "moduleFileExtensions": [
      "ts",
      "js",
      "json"
    ]
  },
  "keywords": [
    "cipher",
    "cipherbrowser",
    "coinbase",
    "coinbasewallet",
    "eth",
    "ether",
    "ethereum",
    "etherium",
    "injection",
    "toshi",
    "wallet",
    "walletlink",
    "web3"
  ],
  "license": "Apache-2.0",
  "main": "dist/index.js",
  "name": "walletlink",
  "repository": {
    "type": "git",
    "url": "git+https://github.com/walletlink/walletlink.git"
  },
  "scripts": {
    "build": "node compile-assets.js && webpack --config webpack.config.js",
    "build-chrome": "webpack --config webpack.config.chrome.js",
    "build-npm": "tsc -p ./tsconfig.build.json",
    "build:dev": "export WALLETLINK_URL='http://localhost:3000'; yarn build && yarn build-chrome",
    "build:dev:watch": "nodemon -e 'ts,tsx,js,json,css,scss,svg' --ignore 'src/**/*-css.ts' --ignore 'src/**/*-svg.ts' --watch src/ --watch chrome/ --exec 'yarn build:dev'",
    "build:prod": "yarn build && yarn build-chrome && yarn build-npm && cp ./package.json ../README.md ../LICENSE build/npm && cp -a src/vendor-js build/npm/dist && sed -i.bak 's|  \"private\": true,||g' build/npm/package.json && rm -f build/npm/package.json.bak",
    "lint": "tslint -p . 'src/**/*.ts{,x}'",
    "lint:watch": "nodemon -e ts,tsx,js,json,css,scss,svg --watch src/ --exec 'yarn tsc && yarn lint'",
    "test": "yarn build-npm && karma start",
    "tsc": "tsc --noEmit --pretty"
  },
  "types": "dist/index.d.ts",
  "version": "2.2.6"
}
