export default {
  setErrorMsg(state, error){
    let message = error.message || 'Error please try latter';
    if(message === "Cannot read property 'net' of undefined") {
      state.errorMsg = "Check that you are in the correct network";
    } else {
      state.errorMsg = message;
    }
  },
  emptyCollection(state){
    state.errorMsg = "";
    state.collectable = {
      baseURI: "",
      isOwner: false,
      saleState: false
    }
  },
  setIsTheOwner(state, { isOwner }){
    state.errorMsg = "";
    state.collectable.isOwner = isOwner;
  },
  setOwner(state, { owner }){
    state.errorMsg = "";
    state.collectable.owner = owner;
  },
  setBaseURI(state, { baseURI }){
    state.errorMsg = "";
    state.collectable.baseURI = baseURI;
  },
  setProvenanceHash(state, { provenanceHash }){
    state.errorMsg = "";
    state.collectable.provenanceHash = provenanceHash;
  },
  setTotalSupply(state, { totalSupply }) {
    state.errorMsg = "";
    state.collectable.totalSupply = totalSupply;
  },
  setSaleState(state, { saleState }) {
    state.errorMsg = "";
    state.collectable = {
      ...state.collectable,
      saleState
    }
  },
  setPreSaleState(state, { preSaleState }) {
    state.errorMsg = "";
    state.collectable = {
      ...state.collectable,
      preSaleState
    }
  },
  flipSaleState(state, { saleState }) {
    state.errorMsg = "";
    state.collectable = {
      ...state.collectable,
      saleState: !state.collectable.saleState,
    }
  },
  flipPreSaleState(state, { preSaleState }) {
    state.errorMsg = "";
    state.collectable = {
      ...state.collectable,
      preSaleState: !state.collectable.preSaleState,
    }
  },
  setGettingUserNFTs(state, value){
    state.collectable.gettingUserNFTs = value;
  },
  setUserNFTs(state, userNFTs){
    state.errorMsg = "";
    state.collectable = {
      ...state.collectable,
      userNFTs,
    }
  },
}
