import collectableService from '../../services/collectable';

export default {
  mintNfts: async({ commit, dispatch }, { numNFTs }) => {
    collectableService.mintNfts(numNFTs)
  },
  mintNFTPresale: async({ commit, dispatch }, {}) => {
    collectableService.mintNFTPresale()
  },
  getOwner: async({ commit, dispatch }) => {
    collectableService.getOwner()
      .then(res => commit('setOwner', { owner: res}))
      .catch(e => commit('setErrorMsg', e))
  },
  getProvenanceHash: async({ commit, dispatch }) => {
    collectableService.getProvenanceHash()
      .then(res => commit('setProvenanceHash', { provenanceHash: res }))
      .catch(e => commit('setErrorMsg', e))
  },
  getSaleState: async({ commit, dispatch }) => {
    collectableService.getSaleState()
      .then(res => commit('setSaleState', { saleState: res }))
      .catch(e => commit('setErrorMsg', e))
  },
  getPreSaleState: async({ commit, dispatch }) => {
    collectableService.getPreSaleState()
      .then(res => commit('setPreSaleState', { preSaleState: res }))
      .catch(e => commit('setErrorMsg', e))
  },
  setTotalSupply: async({ commit }) => {
    collectableService.getTotalSupply()
      .then(res => commit('setTotalSupply', {totalSupply: res}))
      .catch(e => commit('setErrorMsg', e))
  },
  flipSaleState: async({ commit, dispatch }) => {
    collectableService.flipSaleState()
      .then(res => commit('flipSaleState'))
      .catch(e => commit('setErrorMsg', e))
  },
  flipPreSaleState: async({ commit, dispatch }) => {
    collectableService.flipPreSaleState()
      .then(res => commit('flipPreSaleState'))
      .catch(e => commit('setErrorMsg', e))
  },
  transferOwnership: async({ commit, dispatch }, { newOwner }) => {
    collectableService.transferOwnership(newOwner)
      .then(res => commit('setOwner', res))
      .catch(e => commit('setErrorMsg', e))
  },
  setBaseURI: async({ commit, dispatch }, { newBaseURI }) => {
    collectableService.setBaseURI(newBaseURI)
      .then(res => commit('setBaseURI', res))
      .catch(e => commit('setErrorMsg', e))
  },
  getBaseURI: async({ commit, dispatch }) => {
    collectableService.getBaseURI()
      .then(res => commit('setBaseURI', { baseURI: res }))
      .catch(e => commit('setErrorMsg', e))
  },
  setProvenanceHash: async({ commit, dispatch }, { provenanceHash }) => {
    collectableService.setProvenanceHash(provenanceHash)
      .then(res => commit('setProvenanceHash', { provenanceHash }))
      .catch(e => commit('setErrorMsg', e))
  },
  withdraw: async({ commit, dispatch }) => {
    collectableService.withdraw()
  },
  isTheOwner: async ({commit}, {}) => {
    collectableService.isTheOwner()
      .then(res => commit('setIsTheOwner', { isOwner: res }))
      .catch(e => commit('setErrorMsg', e))
  },
  getUserTokens: async({ commit, dispatch, rootState}, {totalSupply}) => {
    try {
      commit('setGettingUserNFTs', true)
      const userNFTs = [];
      // get wallet address
      const userAddress = rootState.wallet.wallet;
      // get the amount of tokens the user has
      const tokensAmount = await collectableService.getUserTokenAmount(userAddress)
      // loop it and call tokenOfOwnerByIndex
      for (let i = 0; i < tokensAmount; i++) {
        const id = await collectableService.tokenOfOwnerByIndex(userAddress, i);
        const uri = await collectableService.getTokenURIById(id)        
        userNFTs.push({ id, uri });
      }
      
      //commit the mutation to set userNFTs with the userNFTs array
      commit('setUserNFTs', userNFTs)
      commit('setGettingUserNFTs', false)

    } catch(e) {
      commit('setErrorMsg', e);
      commit('setGettingUserNFTs', false)

    }
  },
}
