import WalletConnectProvider from "@walletconnect/web3-provider";
import WalletLink from "walletlink"
import Web3 from "web3"

const walletLink = new WalletLink({
  appName: "Incognito",
  darkMode: false
})

// //  Create WalletConnect Provider
const providerWalletConnect = new WalletConnectProvider({
  infuraId: process.env.INFURA_PROJECT_ID
});
const providerCoinbaseWallet = walletLink.makeWeb3Provider(
  `https://mainnet.infura.io/v3/${process.env.INFURA_PROJECT_ID}`, 1
)

const Wallet = {
  address: '',
  async connect(wallet) {
    if (wallet === 'walletConnect') {
      await providerWalletConnect.enable();
      window.web3 = new Web3(providerWalletConnect);
    } 
    else if(wallet === 'coinbase') {
      await providerCoinbaseWallet.enable();
      window.web3 = new Web3(providerCoinbaseWallet)
    }
    else if(wallet === 'metamask' || window.ethereum) {
      await window.ethereum.send('eth_requestAccounts');
      window.web3 = new Web3(window.ethereum);
    }
    const accounts = await web3.eth.getAccounts();
    this.address = accounts[0];
    return this.address;
  },
  async disconnectedWalletConnect() {
    await providerWalletConnect.disconnect()
  },
  async disconnectedCoinbaseWallet() {
    await walletLink.disconnect()
  }
}
export default Wallet;
