{
    "name": "mainnet",
    "chainId": 1,
    "networkId": 1,
    "defaultHardfork": "istanbul",
    "consensus": {
        "type": "pow",
        "algorithm": "ethash",
        "ethash": {}
    },
    "comment": "The Ethereum main chain",
    "url": "https://ethstats.net/",
    "genesis": {
        "hash": "0xd4e56740f876aef8c010b86a40d5f56745a118d0906a34e69aec8c0db1cb8fa3",
        "timestamp": null,
        "gasLimit": 5000,
        "difficulty": 17179869184,
        "nonce": "0x0000000000000042",
        "extraData": "0x11bbe8db4e347b4e8c937c1c8370e4b5ed33adb3db69cbdb7a38e1e50b1b82fa",
        "stateRoot": "0xd7f8974fb5ac78d9ac099b9ad5018bedc2ce0a72dad1827a1709da30580f0544"
    },
    "hardforks": [
        {
            "name": "chainstart",
            "block": 0,
            "forkHash": "0xfc64ec04"
        },
        {
            "name": "homestead",
            "block": 1150000,
            "forkHash": "0x97c2c34c"
        },
        {
            "name": "dao",
            "block": 1920000,
            "forkHash": "0x91d1f948"
        },
        {
            "name": "tangerineWhistle",
            "block": 2463000,
            "forkHash": "0x7a64da13"
        },
        {
            "name": "spuriousDragon",
            "block": 2675000,
            "forkHash": "0x3edd5b10"
        },
        {
            "name": "byzantium",
            "block": 4370000,
            "forkHash": "0xa00bc324"
        },
        {
            "name": "constantinople",
            "block": 7280000,
            "forkHash": "0x668db0af"
        },
        {
            "name": "petersburg",
            "block": 7280000,
            "forkHash": "0x668db0af"
        },
        {
            "name": "istanbul",
            "block": 9069000,
            "forkHash": "0x879d6e30"
        },
        {
            "name": "muirGlacier",
            "block": 9200000,
            "forkHash": "0xe029e991"
        },
        {
            "name": "berlin",
            "block": 12244000,
            "forkHash": "0x0eb440f6"
        },
        {
            "name": "london",
            "block": 12965000,
            "forkHash": "0xb715077d"
        },
        {
            "name": "merge",
            "block": null,
            "forkash": null
        },
        {
            "name": "shanghai",
            "block": null,
            "forkash": null
        }
    ],
    "bootstrapNodes": [
        {
            "ip": "18.138.108.67",
            "port": 30303,
            "id": "d860a01f9722d78051619d1e2351aba3f43f943f6f00718d1b9baa4101932a1f5011f16bb2b1bb35db20d6fe28fa0bf09636d26a87d31de9ec6203eeedb1f666",
            "location": "ap-southeast-1-001",
            "comment": "bootnode-aws-ap-southeast-1-001"
        },
        {
            "ip": "3.209.45.79",
            "port": 30303,
            "id": "22a8232c3abc76a16ae9d6c3b164f98775fe226f0917b0ca871128a74a8e9630b458460865bab457221f1d448dd9791d24c4e5d88786180ac185df813a68d4de",
            "location": "us-east-1-001",
            "comment": "bootnode-aws-us-east-1-001"
        },
        {
            "ip": "34.255.23.113",
            "port": 30303,
            "id": "ca6de62fce278f96aea6ec5a2daadb877e51651247cb96ee310a318def462913b653963c155a0ef6c7d50048bba6e6cea881130857413d9f50a621546b590758",
            "location": "eu-west-1-001",
            "comment": "bootnode-aws-eu-west-1-001"
        },
        {
            "ip": "35.158.244.151",
            "port": 30303,
            "id": "279944d8dcd428dffaa7436f25ca0ca43ae19e7bcf94a8fb7d1641651f92d121e972ac2e8f381414b80cc8e5555811c2ec6e1a99bb009b3f53c4c69923e11bd8",
            "location": "eu-central-1-001",
            "comment": "bootnode-aws-eu-central-1-001"
        },
        {
            "ip": "52.187.207.27",
            "port": 30303,
            "id": "8499da03c47d637b20eee24eec3c356c9a2e6148d6fe25ca195c7949ab8ec2c03e3556126b0d7ed644675e78c4318b08691b7b57de10e5f0d40d05b09238fa0a",
            "location": "australiaeast-001",
            "comment": "bootnode-azure-australiaeast-001"
        },
        {
            "ip": "191.234.162.198",
            "port": 30303,
            "id": "103858bdb88756c71f15e9b5e09b56dc1be52f0a5021d46301dbbfb7e130029cc9d0d6f73f693bc29b665770fff7da4d34f3c6379fe12721b5d7a0bcb5ca1fc1",
            "location": "brazilsouth-001",
            "comment": "bootnode-azure-brazilsouth-001"
        },
        {
            "ip": "52.231.165.108",
            "port": 30303,
            "id": "715171f50508aba88aecd1250af392a45a330af91d7b90701c436b618c86aaa1589c9184561907bebbb56439b8f8787bc01f49a7c77276c58c1b09822d75e8e8",
            "location": "koreasouth-001",
            "comment": "bootnode-azure-koreasouth-001"
        },
        {
            "ip": "104.42.217.25",
            "port": 30303,
            "id": "5d6d7cd20d6da4bb83a1d28cadb5d409b64edf314c0335df658c1a54e32c7c4a7ab7823d57c39b6a757556e68ff1df17c748b698544a55cb488b52479a92b60f",
            "location": "westus-001",
            "comment": "bootnode-azure-westus-001"
        }
    ],
    "dnsNetworks": [
        "enrtree://AKA3AM6LPBYEUDMVNU3BSVQJ5AD45Y7YPOHJLEF6W26QOE4VTUDPE@all.mainnet.ethdisco.net"
    ]
}
